html, body {
    margin: 0;
    padding: 0;
    background: #f9f9fb;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #1D1D1F;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: #f9f9fb;
  }
  
/* Header Section */
.header {
    padding: 30px 20px;
    text-align: center;
    /* A dark, mature gradient background */
    background: linear-gradient(90deg, #1a1a1a, #2f2f2f);
    
    /* A slightly stronger box-shadow for depth on a dark header */
    box-shadow: 0 2px 10px rgba(0,0,0,0.4);
    
    /* Remove the light border since it won't be visible against dark background */
    border-bottom: none;
  }
  
  .header h1 {
    font-weight: 600;
    font-size: 1.8rem;
    margin: 0;
    /* Set letter-spacing to normal for a clean, professional look */
    letter-spacing: normal;
    
    /* White text for contrast, subtle text-shadow for depth */
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
  }
  
  /* Chat Area */
  .chat-area {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    padding: 30px 20px;
    background: #ffffff;
    background: linear-gradient(to bottom, #ffffff, #f6f6f9);
  }
  
  .chat-area::-webkit-scrollbar {
    width: 8px;
  }
  .chat-area::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
  
  /* Input Bar */
  .input-bar {
    display: flex;
    align-items: center;
    padding: 20px;
    border-top: 1px solid #e0e0e0;
    background: #fbfbfc;
    box-shadow: 0 -1px 5px rgba(0,0,0,0.03);
  }
  
  .input-bar input {
    flex: 1;
    font-size: 1rem;
    padding: 12px 14px;
    border: 1px solid #d0d0d0;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    background: #fff;
  }
  
  .input-bar input:focus {
    border-color: #999;
    box-shadow: 0 0 0 2px rgba(0,0,0,0.05);
  }
  
  .input-bar button {
    margin-left: 10px;
    font-size: 1rem;
    font-weight: 500;
    padding: 10px 18px;
    background: #1D1D1F;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.1s ease;
  }
  
  .input-bar button:hover {
    background: #333;
  }
  
  .input-bar button:active {
    transform: scale(0.98);
  }
  
  .input-bar button[disabled] {
    background: #888;
    cursor: not-allowed;
  }
  
  /* Message Bubbles */
  .message-bubble {
    max-width: 70%;
    padding: 14px 18px;
    border-radius: 14px;
    margin-bottom: 20px;
    font-size: 1rem;
    line-height: 1.5;
    animation: fadeInUp 0.2s ease forwards;
    opacity: 0;
    transform: translateY(10px);
    box-shadow: 0 2px 5px rgba(0,0,0,0.05);
  }
  
  .message-bubble.user {
    margin-left: auto;
    background: #1D1D1F;
    color: #fff;
    text-align: right;
    border-bottom-right-radius: 4px;
  }
  
  .message-bubble.bot {
    margin-right: auto;
    background: #f2f2f7;
    color: #1D1D1F;
    border-bottom-left-radius: 4px;
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  